$mobile: 768px;

.projects-page{
    margin-top: 4%;
    @media screen and (max-width: $mobile) {
        margin-top: 8%;
    }
    .text-zone{
        width: 40% !important;
        h1{
            @media screen and (max-width: $mobile) {
                margin-bottom: 15px;
            }
        }
        @media screen and (max-width: $mobile) {
            width: 75% !important;
        }
        .project-container{
            margin-bottom: 5%;
            h1{
                margin: 4rem 0;
            }
        }
        .project{
            font-weight: bold;
            position: relative;
            color: #fff;
            font-size: 35px;
            @media screen and (max-width: $mobile) {
                font-size: 25px;
            }
        }
        a{
            text-decoration: none;
            color: #ffd700;
        }
        svg{
            margin-left: 5px;
        }
        .tech-icons{
            display: inline;
            position: relative;
            img{
                vertical-align: -8%;
                position: relative;
                height: 20px;
                margin-left: 0.4rem;
            }
        }
        .technologies{
            margin-bottom: 0.7rem;
        }
        .flat-button{
            background-color: #022c43;
            cursor: pointer;
            display: inline;
            color: #ffd700;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 1px solid #ffd700;
            white-space: nowrap;
            margin-right: 10px;
            &:hover{
                background: #ffd700;
                color: #333;
            }
            &:nth-last-of-type(1){
                animation: fadeIn 1s 1.8s backwards;
            }
            &:nth-last-of-type(1){
                animation: fadeIn 1s 2s backwards;
            }
            @media screen and (max-width: $mobile) {
                padding: 6px 14px;
                font-size: 10px;
            }
        }
    }
}
.pagination{
    z-index: 1;
    position: absolute;
    bottom: 5%;
    left: 50%;
    .fa-disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
    button{
        cursor: pointer;
        color: #ffd700;
        background: #022c43;
        border: 1px solid #ffd700;
        padding: 0.5rem 1rem;
        margin-right: 0.6rem;
        &:hover{
            background: #ffd700;
            color: #333;
        }
    }
}
.load-in-active{
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    opacity: 0;
}
.load-in-done{
    animation: pulse 1s;
    &:nth-of-type(1){
        animation-delay: 1.1s;
    }
    &:nth-of-type(2){
        animation-delay: 1.2s;
    }
}
.projectText{
    display: block;
    font-size: 20px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    min-width: fit-content;
    margin-bottom: 1.5rem;
    @media screen and (max-width: $mobile) {
        font-size: 18px;
    }
}
