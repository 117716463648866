$mobile: 768px;

.home-page{
    .text-zone{
        position: absolute;
        top: 50%;
        left:10%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        @media screen and (max-width: $mobile) {
            width: 75%;
            left: 75px;
            text-align: center;
        }
    }
    h1{
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
        
        &::before{
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }
        &::after{
            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        @media screen and (max-width: $mobile) {
            &::before{
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 10px;
            opacity: 0.6;
            right: 90px;

            }
            &::after{
                content: "</h1>";
                font-family: "La Belle Aurore";
                color: #ffd700;
                font-size: 18px;
                position: absolute;
                margin-top: 10px;
                animation: fadeIn 1s 1.7s backwards;
                opacity: 0.6;
            }
        }
        img{
            width: 32px;
            margin-left: 20px;
            margin-right: 2px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            @media screen and (max-width: $mobile) {
                width: 15px;
            }
        }
        @media screen and (max-width: $mobile) {
            font-size: 28px;
            font-weight: 200;
        }
    }
    h2{
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        margin-bottom: 30px;
    }
    .flat-button{
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        animation: fadeIn 1s 1.8s backwards;
        &:hover{
            background: #ffd700;
            color: #333;
        }
    }
}

