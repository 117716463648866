$mobile: 768px;
.logo-container {
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    animation: tada 0.8s;
    animation-delay: 2s;
    z-index: -5;
    @media screen and (max-width: $mobile) {
        top: 110%;
        right: 20%;
        width: 180px;
        left: 25%;
    }
    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        transform: rotateZ(20deg) !important;
        z-index: -5;
    }
}
